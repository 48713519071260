import React, { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography, Card, CardContent, Avatar, IconButton } from "@mui/material";
import { LinkedIn } from "@mui/icons-material";
import Sachin from "../../assets/images/team/sachin.jpg";
import Aishwary from "../../assets/images/team/aishwaryaImage.jpg";
import Deepak from "../../assets/images/team/deepak.jpeg";
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

const teamMembers = [
  { name: "Sachin Kocharekar",designation:'Founder, Board of Director', description: "Sachin Kocharekar is the visionary founder and Board of Director of CurioStuff, bringing over 12 years of experience in Software Technologies. He specializes in Application Solution Architecture and Business Expertise in Development and Planning. Sachin completed his engineering degree with a specialization in Computer Science from MITK. In addition to his role at CurioStuff, Sachin is the CEO of Invayas, the IT Director at TagSkills, and the CEO of Coralframes. He is a seasoned expert in developing stock market products, healthcare products, CRM products, and educational products. Previously, Sachin served as the IT Head of Agasinc Technologies, where he leveraged his skills to drive growth and empower businesses through cutting-edge technology.", image: Sachin, linkedin: "https://www.linkedin.com/in/sachin-k-40799142/"  },
];

const Support = () => {
  const theme = useTheme();

  const [isMobile, setIsMobile] = useState(false);

  // Function to check screen size and update state
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Consider screen width <= 768px as mobile
  };

  // Hook to handle screen resize
  useEffect(() => {
    handleResize(); // Check size on initial render
    window.addEventListener("resize", handleResize); // Add event listener for window resize

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <Box p={4}>
      <Typography
        textAlign="center"
        variant="h3"
        mb={4}
        sx={{
          fontSize: { xs: "2rem", md: "2rem" },
          fontWeight: "bold",
          marginBottom: 2,
        }}
      >
        People
      </Typography>
      <Grid container spacing={4} justifyContent="center">

        {teamMembers.map((member, index) => (

          <Grid item xs={12} sm={6} md={12} key={index}>

{isMobile ? ( <Card  display="flex"  variant="none" sx={{ display: "flex"}}>
          <Box  sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
            <Avatar
              src={member.image}
              sx={{
                width: 250,
                height: 250,
                objectFit: "cover",
              }}
            />
            <Typography gutterBottom variant="h5" component="div">
              {member.name}
            </Typography>
            <Typography gutterBottom variant="subtitle2" component="div">
            {member.designation}
            </Typography>
            <Typography variant="body1" color="text.secondary"
            sx={{
              margintop: 1,
              textAlign: 'justify',
            }}>
              {member.description}
              </Typography>
          </Box>
        </Card>
        ) :(
              <Card  display="flex"  variant="none" sx={{ display: "flex"}}>
          <Box  sx={{
                marginLeft:10,marginRight:10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
            <Avatar
              src={member.image}
              sx={{
                width: 250,
                height: 250,
                objectFit: "cover",
              }}
            />
            <Typography gutterBottom variant="h5" component="div">
              {member.name}
            </Typography>
            <Typography gutterBottom variant="subtitle2" component="div" >
            {member.designation}
            </Typography>
          </Box>
          <Box>
    
              <Typography variant="body1" color="text.secondary" 
               sx={{
                textAlign: 'justify',
              }}>
              {member.description}
              </Typography>
          
          </Box>
        </Card>
        )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Support;
